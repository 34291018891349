import WorshipSong from '@data/interfaces/song.interface';
import './index.scss'
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '@auth/AuthProvider';
import NetworkController, { HTTPMethod, HTTPRequest } from '@data/controller/NetworkController';

import shareIcon from '@icon/share.svg'
import trashIcon from '@icon/trash.svg'
import plusIcon from '@icon/plus.svg'

import { useChurchDataProvider } from '@data/ChurchDataProvider';
import PageHeader from '@components/PageHeader';

interface WorshipPlaylist {
  id: string
  title: string
  description: string | null
  songs: WorshipSong[]
  links: Record<string, string>
  createdAt: string // timestamp
}

interface Library {
  worshipPlaylists: WorshipPlaylist[];
}

export default function Playlists() {
  const auth = useAuth();
  const churchData = useChurchDataProvider();
  const [library, setLibrary] = useState<Library | null>(null);

  const fetchLibrary = useCallback(async () => {
    if (!auth.churchId) return; // haven't loaded yet

    const res = await NetworkController.performRequest<{
      result: Library
    }>(
      new HTTPRequest(HTTPMethod.GET, `/church/${auth.churchId}/library/worship`),
      auth.consumeRefreshToken
    )

    if (res.body) {
      setLibrary(res.body.result);
    } else {
      console.error(res)
    }
  }, [auth.churchId])

  useEffect(() => {
    fetchLibrary()
  }, [fetchLibrary])

  const createPlaylist = async (playlistURL: string) => {
    const res = await NetworkController.performRequest<{
      result: WorshipPlaylist
    }>(
      new HTTPRequest(HTTPMethod.POST, `/church/${auth.churchId}/library/worship/playlists`, {
        playlistURL
      }),
      auth.consumeRefreshToken
    )

    if (res.body) {
      setLibrary({
        worshipPlaylists: [...library?.worshipPlaylists || [], res.body.result]
      })

      window.alert("Playlist created!");
    } else {
      console.error(res)
      alert('Failed to create playlist');
    }
  }

  const deletePlaylist = async (playlistId: string) => {
    const res = await NetworkController.performRequest<{
      result: boolean
    }>(
      new HTTPRequest(HTTPMethod.DELETE, `/church/${auth.churchId}/library/worship/playlists/${playlistId}`),
      auth.consumeRefreshToken
    )

    if (res.body) {
      setLibrary({
        worshipPlaylists: library?.worshipPlaylists.filter(playlist => playlist.id !== playlistId) || []
      })
    } else {
      console.error(res)
      alert('Failed to delete playlist');
    }
  }

  return <div className="playlists">
    <PageHeader title="Playlists" 
      subtitle="Create playlists for your church to listen to."
      showBackButton={false}
      links={
        [
          {
            title: 'Create Playlist',
            onClick: () => {
              const playlistURL = window.prompt('Enter the URL of the playlist you want to create');
              if (!playlistURL) return;

              createPlaylist(playlistURL);
            },
            icon: plusIcon
          }
        ]
      } />

    <div className="playlists-list">
      {library?.worshipPlaylists?.sort((a, b) => a.createdAt < b.createdAt ? 1 : 0).map(playlist => (
        <div key={playlist.id} className="playlist">
          <div className='background'>
            <div>
              <h2 className='title'>{playlist.title}</h2>
            </div>

            <div className='options'>
              <a href={`${process.env.REACT_APP_SALT_PLAYLISTS_URL}/${churchData.church?.id}/${playlist.id}`}
                target='_blank'
                rel="noopener noreferrer"
                title="Open Playlist Link">
                <img src={shareIcon} alt="Share" />
              </a>
              <button onClick={async (e) => {
                e.preventDefault();

                const shouldDelete = window.confirm('Are you sure you want to delete this playlist?');
                if (!shouldDelete) return;

                await deletePlaylist(playlist.id);
              }}
                title="Delete Playlist">
                <img src={trashIcon} alt="Delete" />
              </button>
            </div>
          </div>

          <PlaylistDetail playlist={playlist} />
        </div>
      ))}
    </div>
  </div>
}

const PlaylistDetail = (props: {
  playlist: WorshipPlaylist
}) => {
  return <ul>
    {props.playlist.songs.map(song => (
      <li key={song.id} className='song'>
        <img src={song.albumArtURL} alt={song.songTitle} className='cover-art' />
        <div>
          <h1>{song.songTitle}</h1>
          <p>{song.artistName}</p>

          {/* <span className='buttons'>
            <button onClick={async (e) => {
              e.preventDefault();

              const url = song.links.apple;
              window.open(url, '_blank');
            }}>
              Open Apple Link
              <img src={linkArrow} alt="Open Apple Link" />
            </button>

            <button onClick={(e) => {
              e.preventDefault();

              const url = song.links.spotify;
              window.open(url, '_blank');
            }}>
              Open Spotify Link
              <img src={linkArrow} alt="Open Spotify Link" />
            </button>
          </span> */}
        </div>
      </li>
    ))}
  </ul>
}